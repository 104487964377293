<template>
  <div>
      <div class="reader">
      <ul>
        <li v-for="log in logs" :key="log.id">
          <b-card>
            <p>{{ log.id }} - {{ log.operation }} - {{ log.object_type }}<span v-if="log.operation == 'UPDATE'">&nbsp;[{{ log.action}}]</span></p>
            <p v-if="log.operation != 'INSERT'">Object ID : {{ log.object_id}}</p>
            <p v-if="log.operation != 'DELETE'">Changes : {{ log.value}}</p>
            <p class="error">{{ log.lastError }}</p>
            <b-button @click="remove(log.id)">Supprimer ce log</b-button>
          </b-card>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { RST_LOG } from '@/LocalDB';

export default {
  computed:{
    logs(){
      return RST_LOG.all();
    }
  },
  methods:{
    remove(id)
    {
      RST_LOG.delete(id);
    }
  }
}
</script>

<style scoped>
  .reader{ max-width: 100%; width:100%; min-height:100px; overflow-x: auto;}
  .error{color:red; font-style:italic;}
</style>

