<template>
    <div>
      <h1>Configuration</h1>
      <fieldset class="fieldset" v-if="isDebugMode">
        <legend>Debug Mode</legend>
        <form-field :caption="'Bypass Security ' + (byPassSecurity ? 'ON' : 'OFF')" >
          <template #info-helper-placeholder>
            <info-helper modal-size="sm">
              <b-checkbox switch size="lg" v-model="byPassSecurity">Activation du mode "ByPass Security"</b-checkbox>&nbsp;
              <br/>
              En mode DEBUG, il est possible de contourner toutes les règles de vérification des droits et ainsi donner un accès complet
              aux différentes parties de l'application.<br/>
              ATTENTION ! Cela ne permet pas, par contre, de s'assurer que la gestion des droits fonctionne correctement.
            </info-helper>
          </template>
        </form-field>
        <form-field :caption="'Mode simulation d\'application mobile ' + (simulMobileApp ? 'ON' : 'OFF')">
          <template #info-helper-placeholder>
            <info-helper modal-size="sm">
              <b-checkbox switch size="lg" v-model="simulMobileApp">Activation du mode "Simulation d'application mobile"</b-checkbox>&nbsp;
              <br/>
              En mode DEBUG, il est possible de basculer sur un mode d'affichage correspondant à celui utilisé dans le cadre de l'application mobile.
            </info-helper>
          </template>
        </form-field>
      </fieldset>

      <platform-area>
        <template #web>
          
        </template>
        <template #mobile>
          <collapse-panel>
            <template #title><h2>Mon compte <b-icon icon="pencil" @click="showMyAccount"></b-icon></h2></template>
            <h3>Identification</h3>
            <table class="properties-table" v-if="isAuthenticated">
              <tr>
                <th>
                  <b>Login</b>
                  <info-helper>
                    <b>Login = Username = Identifiant de connexion</b><br/>
                    Représente le nom avec lequel vous pouvez vous connecter via l'écran de connexion vous demandant la saisie de votre <b>Login</b> et de votre <b>Mot de passe</b>.<br/>
                    <u>Vous ne pouvez pas</u> changer votre login. Par contre vous pouvez demander à un administrateur de le faire pour vous.<br/>
                  </info-helper>
                </th>
                <td>
                  <b-input type="text" readonly v-model="currentUser.username" placeholder="Login" />
                </td>
              </tr>
              <tr><th>Password</th><td><input type="password" readonly v-model="currentUser.passwordTemplate"/></td></tr>
            </table>
            <h3>Etat civil</h3>
            <table class=""></table>
          </collapse-panel>
        </template>
      </platform-area>



      <secured-area :operations="['EST_TESTEUR']" hideWhenAccessIsRejected>
        <fieldset class="fieldset">
          <legend>Espace Tests</legend>
          <form-field :caption="'Test mode ' + (noAuth ? 'ON' : 'OFF')">
            <template #info-helper-placeholder>
                <info-helper modal-size="xl">
                  <b-checkbox switch size="lg" v-model="noAuth" @change="noAuthChanged">Activation du mode test</b-checkbox>&nbsp;
                     <br/>
                    Le mode test permet au utiliisateurs d'apprende à se servir de l'outil par la pratique sans risque pour les vraies données.
                    <br/>
                    En mode "test" les compétitions que vous gèrerez ne seront visibles ques pour les périphériques également en mode test.
                    Toute nouvelle compétition sera obligatoirement créée en mode "test" et ne comptera donc pas dans les classements.
                    <br/>
                    <strong>ATTENTION</strong>, cependant ! Le mode test ne concerne que les "COMPETITIONS" mais pas les données personnelles des JUGES ou des COMPETITEURS.
                    <i>Cela signifie que si vous ajoutez, modifiez ou supprimez un juge ou un compétiteur (ou la composition d'un compétiteur de type paire ou équipe), ces actions seront réellement prises en compte y-compris dans le mode "réél".</i>
                </info-helper>
            </template>
          </form-field>
        </fieldset>
      </secured-area>

        <b-button variant="danger" v-b-modal.dlgConfirmLocalDBReset>Ré-initialiser la base de donnée locale (hors Métadonnées)</b-button>
        <b-modal
            id="dlgConfirmLocalDBReset"
            title="Confirmation d'action"
            centered
            :static="true"
            :lazy="true"
            size="sm"
            ok-variant="danger"
            ok-title="Oui"
            cancel-variant="secondary"
            cancel-title="Non"
            button-size="lg"
            @ok="resetLocalDB"
            >
            Etes-vous certain de vouloir vider la base locale des toutes les données de compétition en cours ?
        </b-modal>

        <b-button v-if="userHasRole(Roles.Developper)" variant="warning" @click="setTemporaryTestData">Données de test temporaires</b-button>

        <fieldset class="fieldset">
          <legend>Options</legend>
          <b-checkbox switch v-model="confirmNoteEntryByPopup">Confirmation des notes saisies par fenêtre modale.</b-checkbox>
          <b-checkbox switch v-model="skipNotesByComponents">Retrait "détaillé" (par composante) des notes hautes et basse</b-checkbox>
          <b-checkbox switch v-model="verboseNoteSummary">Résumé des notes "verbeux"</b-checkbox>
          <form-field caption="Delai d'attente entre 2 appels pour vérification de saisie d'un autre juge (en sec.)">
            <b-form-input type="number" min="1" max="60" step="0.25" v-model="delayBetweenJudgeNoteCheck" />
          </form-field>
        </fieldset>

        <mobile-app-area>
          <fieldset class="fieldset">
              <legend>Accès au serveur</legend>
              <b-form-group label="Adresse du serveur">
                  <input type="text" v-model="serverUrl" @input="serverAddress_input"/>
              </b-form-group>
              <div v-if="serverAddressCheckResult != ''">
                {{ serverAddressCheckResult }}
              </div>
              <b-button @click="checkServerAddress">Vérifier l'accès</b-button>
              <b-button @click="changeServerAddress">Enregistrer</b-button>
          </fieldset>
        </mobile-app-area>

        <fieldset class="fieldset">
          <legend>Logs d'erreurs de synchronisation</legend>
          <synchro-logs />
        </fieldset>
        <secured-area :roles="[Roles.Developper]">
          <fieldset class="fieldset">
            <b-button variant="danger" @click="reset_AllPeopleData">Reset des toutes les données "Personne"</b-button>
          </fieldset>
          <fieldset class="fieldset">
            <dump />
          </fieldset>
          <fieldset class="fieldset">
            <legend>Check right on operation</legend>
            <b-form-input v-model="operationToCheck" @keydown="operationCheckResult=''" />
            <b-button @click="checkRightOnOperation">Vérifier</b-button>
            <span v-show="operationCheckResult == 'Autorisé'" class="success">{{ operationCheckResult }}</span>
            <span v-show="operationCheckResult != 'Autorisé'" class="error">{{ operationCheckResult }}</span>
          </fieldset>
        </secured-area>

        <secured-area>
          <fieldset class="fieldset">
            <legend>Info sur l'appareil</legend>
            <b-container>
              <b-row>
                <b-col>Mobile :</b-col>
                <b-col>{{ isNativePlatform ? "Oui" : "Non"}}</b-col>
                <b-col />
                <b-col>Plateforme :</b-col>
                <b-col>{{ platform }}</b-col>
              </b-row>
              <b-row>
                <b-col>Taille de l'écran :</b-col>
                <b-col>{{ screenWidth }} x {{ screenHeight }}</b-col>
                <b-col>Taille de la fenêtre (exterieure) :</b-col>
                <b-col>{{ windowOuterWidth }} x {{ windowOuterHeight}}</b-col>
                <b-col />
                <b-col>Taille de la fenêtre (intérieure) :</b-col>
                <b-col>{{ windowInnerWidth }} x {{ windowInnerHeight}}</b-col>
              </b-row>
            </b-container>
          </fieldset>
        </secured-area>

        <b-button variant="danger" v-b-modal.dlgConfirmLocalDBCleanUp>Ré-initialiser (vider intégralement) de la base de donnée locale</b-button>
        <b-modal
            id="dlgConfirmLocalDBCleanUp"
            title="Vidage de la base de données locale"
            centered
            :static="true"
            :lazy="true"
            size="sm"
            ok-variant="danger"
            ok-title="Oui"
            cancel-variant="secondary"
            cancel-title="Non"
            button-size="lg"
            @ok="cleanUpLocalDB"
            >
            Etes-vous certain de vouloir vider intégralement la base locale ?<br/>
            <strong>ATTENTION !</strong>
        </b-modal>
    </div>
</template>

<script>

import { 
  DBTools,
} from "@/LocalDB";

import { STAFF, STAFF_CAPABILITY, LICENSEE, PEOPLE, COMPETITOR, COMPETITOR_MEMBER} from "@/LocalDB"

import { REF_COMPETITION_TYPE, REF_SCOPE, REF_CATEGORY, REF_LEVEL, SEASON, SEASON_COMPETITION_TYPE_CATEGORY_LEVEL } from "@/LocalDB";


import { mapMutations, mapGetters, mapActions } from 'vuex';
import axios from "axios";
import SynchroLogs from '@/components/SynchroLogs.vue';
import SecuredArea from '@/components/Security/SecuredArea.vue';
import Dump from "@/components/Dump";
import InfoHelper from '@/components/InfoHelper.vue';
import { Capacitor } from "@capacitor/core";
import FormField from '../../components/Panels/FormField.vue';
import MobileAppArea from '../../components/MobileAppArea.vue';

export default {
  components: { SynchroLogs, SecuredArea, Dump, InfoHelper, FormField, MobileAppArea },
    data(){
       return {
            noAuth: false,
            noAuthRoles: [],
            serverUrl: '',
            serverAddressCheckResult: '',
            operationToCheck: '',
            operationCheckResult: '',

            screenWidth: screen.width,
            screenHeight: screen.height,
            windowInnerHeight: window.innerHeight,
            windowInnerWidth: window.innerWidth,
            windowOuterHeight: window.outerHeight,
            windowOuterWidth: window.outerWidth,
        }
    },
    computed:{
        ...mapGetters('auth', ['noAuthRequiredOnThisDevice', 'noAuthModeRoles' ]),
        ...mapGetters('config', ['isTestMode', 'serverBaseUrl', 'cfgVerboseNoteSummary', 'cfgConfirmNoteEntryByPopup', 'cfgSkipNoteByComponent', 'cfgDelayBetweenCallsToCheckNotesOfOtherJudges', 'cfgByPassSecurity', 'isMobileAppSimulationMode']),
        confirmNoteEntryByPopup:{
          get(){ return this.cfgConfirmNoteEntryByPopup; },
          set(val){ this.setCfgConfirmNoteEntryByPopup(val); }
        },
        skipNotesByComponents: {
          get(){ return this.cfgSkipNoteByComponent; },
          set(val){this.setCfgSkipNoteByComponent(val);}
        },
        verboseNoteSummary: {
          get(){ return this.cfgVerboseNoteSummary; },
          set(val){ this.setCfgVerboseNoteSummary(val);}
        },
        delayBetweenJudgeNoteCheck: {
          get(){ return this.cfgDelayBetweenCallsToCheckNotesOfOtherJudges / 1000; },
          set(val){ this.setCfgDelayBetweenCallsToCheckNotesOfOtherJudges(val * 1000);}
        },
        byPassSecurity: {
          get(){ return this.cfgByPassSecurity; },
          set(val){ this.setCfgByPassSecurity(val); }
        },
        simulMobileApp: {
          get(){ return this.isMobileAppSimulationMode; },
          set(val){ this.setMobileAppSimulationMode(val); }
        },
        isNativePlatform(){ return Capacitor.isNativePlatform(); },
        platform(){ return Capacitor.getPlatform(); },

    },
    methods: {
        ...mapMutations('auth', ['setnoAuthRequiredOnThisDevice', 'setNoAuthModeRoles']),
        ...mapActions('auth', ['userCan']),
        ...mapMutations('config', ['setTestMode', 'setServerBaseUrl', 'setCfgVerboseNoteSummary', 'setCfgConfirmNoteEntryByPopup', 'setCfgSkipNoteByComponent', 'setCfgDelayBetweenCallsToCheckNotesOfOtherJudges', 'setCfgByPassSecurity', 'setMobileAppSimulationMode']),
        ...mapMutations('synchro', ['setStaffKey', 'setCompetitorsKey', 'cleanUpAllUpdateKeys']),
        ...mapActions['synchro', ['updateWhenRequired']],
        async resetLocalDB(){
          await this.$showRefresher();
          DBTools.cleanUpLocalDB();

          this.toastSuccess("Données locales de compétition supprimées (hors métadonnées)");

          this.$hideRefresher();
        },
        reset_AllPeopleData(){
          this.$showRefresher()
            .then(() => {
              COMPETITOR.deleteAll();
              COMPETITOR_MEMBER.deleteAll();
              LICENSEE.deleteAll();
              PEOPLE.deleteAll();
              STAFF.deleteAll();
              STAFF_CAPABILITY.deleteAll();
              this.setStaffKey(null);
              this.setCompetitorsKey(null);
              this.updateWhenRequired();
              this.$hideRefresher();
            });
        },

        refresh(){
            this.noAuth = this.isTestMode;
            this.noAuthRoles = this.noAuthModeRoles;
            this.serverUrl = this.serverBaseUrl;
            if(this.serverUrl.endsWith('/'))
                this.serverUrl = this.serverUrl.substring(0, this.serverUrl.length - 1);
        },
        noAuthChanged(value){
            this.setTestMode(value);
            this.noAuth = this.isTestMode;
        },
        noAuthRolesChanged(value){
            this.setNoAuthModeRoles(value);
        },
        serverAddress_input(){
          this.serverAddressCheckResult = '';
        },
        changeServerAddress(){
            var url = this.serverUrl;
            if(url.endsWith('/'))
                url = url.substring(0, url - 1);

            this.setServerBaseUrl(url);
        },
        checkServerAddress(){
          var url = this.serverUrl;
          if(url.endsWith('/'))
            url = url.substring(0, url-1);

          url = url + '/api/test.php';

          axios
              .post(url)
              .then(() => {
                  this.serverAddressCheckResult = 'Accès ok';
              })
              .catch(error => {
                  this.serverAddressCheckResult = 'Accès impossible : ' + error;
              });
        },
        async checkRightOnOperation(){
          var response = await this.userCan(this.operationToCheck);
          if(response)
            this.operationCheckResult = "Autorisé";
          else
            this.operationCheckResult = "Refusé";
        },
        async cleanUpLocalDB(){
          await this.$showRefresher();
          DBTools.cleanUpLocalDB(true, true, true);
          this.cleanUpAllUpdateKeys();
          this.toastSuccess('Nettoyage terminé');
          this.$hideRefresher();
        },
        setTemporaryTestData(){
            REF_COMPETITION_TYPE.insertOrUpdate({
                data: { code: 'CDF_OUT', name: 'CDF Cerf-Volant Acrobatique', shortname: 'CDF CVA', longname: 'Championnat de France Cerf-Volant Acrobatique', isFederal: true, isCva: true }
            });

            REF_SCOPE.insertOrUpdate({ data: { code:'region', name:'Régional', shortname:'Reg.', longname: 'Régional'} });
            REF_SCOPE.insertOrUpdate({ data: { code:'nation', name:'National', shortname:'Nat.', longname: 'National'} });
            REF_SCOPE.insertOrUpdate({ data: { code:'internat', name:'International', shortname:'Internat.', longname: 'International'} });

            REF_LEVEL.insertOrUpdate({ data: { code: 'expert', name: 'Expert', shortname: 'Exp.', longname: 'Expert'} });
            REF_LEVEL.insertOrUpdate({ data: { code: 'novice', name: 'Novice', shortname: 'Nov.', longname: 'Novice'} });

            REF_CATEGORY.insertOrUpdate({ data: { code: 'DI', name: 'Individuel 2L', shortname: 'Indiv 2L', longname: 'Individuel 2 lignes', isIndividual: true} });
            REF_CATEGORY.insertOrUpdate({ data: { code: 'DP', name: 'Paire 2L', shortname: 'Paire 2L', longname: 'Paire 2 lignes', isPair: true} });
            REF_CATEGORY.insertOrUpdate({ data: { code: 'DT', name: 'Equipe 2L', shortname: 'Equipe 2L', longname: 'Equipe 2 lignes', isTeam: true} });
            REF_CATEGORY.insertOrUpdate({ data: { code: 'MI', name: 'Individuel ML', shortname: 'Indiv ML', longname: 'Individuel multi-lignes', isIndividual: true} });
            REF_CATEGORY.insertOrUpdate({ data: { code: 'MP', name: 'Paire ML', shortname: 'Paire ML', longname: 'Paire multi-lignes', isPair: true} });
            REF_CATEGORY.insertOrUpdate({ data: { code: 'MT', name: 'Equipe ML', shortname: 'Equipe ML', longname: 'Equipe multi-lignes', isTeam: true} });

            var currentYear = new Date().getFullYear();

            SEASON.insertOrUpdate({ data: { year: currentYear, yearFinalCdF: currentYear, yearSelectiveCdF: currentYear+1} });

            SEASON_COMPETITION_TYPE_CATEGORY_LEVEL.insertOrUpdate({ data: { year: currentYear, competition_type: 'CDF_OUT', category: 'DI', level: 'expert'} });
            SEASON_COMPETITION_TYPE_CATEGORY_LEVEL.insertOrUpdate({ data: { year: currentYear, competition_type: 'CDF_OUT', category: 'DP', level: 'expert'} });
            SEASON_COMPETITION_TYPE_CATEGORY_LEVEL.insertOrUpdate({ data: { year: currentYear, competition_type: 'CDF_OUT', category: 'DT', level: 'expert'} });
            SEASON_COMPETITION_TYPE_CATEGORY_LEVEL.insertOrUpdate({ data: { year: currentYear, competition_type: 'CDF_OUT', category: 'MI', level: 'expert'} });
            SEASON_COMPETITION_TYPE_CATEGORY_LEVEL.insertOrUpdate({ data: { year: currentYear, competition_type: 'CDF_OUT', category: 'MP', level: 'expert'} });
            SEASON_COMPETITION_TYPE_CATEGORY_LEVEL.insertOrUpdate({ data: { year: currentYear, competition_type: 'CDF_OUT', category: 'MT', level: 'expert'} });

            SEASON_COMPETITION_TYPE_CATEGORY_LEVEL.insertOrUpdate({ data: { year: currentYear, competition_type: 'CDF_OUT', category: 'DI', level: 'novice'} });
            SEASON_COMPETITION_TYPE_CATEGORY_LEVEL.insertOrUpdate({ data: { year: currentYear, competition_type: 'CDF_OUT', category: 'DP', level: 'novice'} });
            SEASON_COMPETITION_TYPE_CATEGORY_LEVEL.insertOrUpdate({ data: { year: currentYear, competition_type: 'CDF_OUT', category: 'DT', level: 'novice'} });
            SEASON_COMPETITION_TYPE_CATEGORY_LEVEL.insertOrUpdate({ data: { year: currentYear, competition_type: 'CDF_OUT', category: 'MI', level: 'novice'} });
            SEASON_COMPETITION_TYPE_CATEGORY_LEVEL.insertOrUpdate({ data: { year: currentYear, competition_type: 'CDF_OUT', category: 'MP', level: 'novice'} });
            SEASON_COMPETITION_TYPE_CATEGORY_LEVEL.insertOrUpdate({ data: { year: currentYear, competition_type: 'CDF_OUT', category: 'MT', level: 'novice'} });
        },
        onResize(){
          this.screenWidth = screen.width;
          this.screenHeight = screen.height;
          this.windowInnernHeight = window.innerHeight;
          this.windowInnerWidth = window.innerWidth;
          this.windowOuterHeight = window.outerHeight;
          this.windowOuterlWidth = window.outerWidth;
       }
    },
    mounted(){
        this.refresh();
        this.$hideLoader();
        this.$nextTick(() => {
          window.addEventListener("resize", this.onResize);
        })
    },
    beforeDestroy(){
      window.removeEventListener('resize', this.onResize);
    },

}
</script>
