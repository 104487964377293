<template>
  <div>
    <b-card>
      <template #title>Dump</template>
      <div>Compétition : {{ currentCompetition ? currentCompetition.name : '-' }}</div>
      <div>Manche : {{ currentRound_number }}</div>
      <div>Niveau : {{ currentLevel_code}}</div>
      <div>Catégorie : {{ currentCategory_code}}</div>
      <div>Epreuve : {{ currentEvent_code}}</div>
      <div>Compétiteur: {{ (currentCompetitor_id != null && currentCompetitor) ? currentCompetitor.name : "-" }}</div>
      <div>Juge : {{ (currentJudge && currentJudge.STAFF) ? currentJudge.STAFF.name : "-"}}</div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed:{
    ...mapGetters('current', ['currentJudge'])
  }
}
</script>
